<template>
  <div class="detail">
    <div class="detail-navs">
      <Nav paths="/" />
    </div>
    <div class="detail-title">
      <div class="main-title">
        {{articleList.title}}
        <div class="detail-goback">
          <el-link type="info" icon="el-icon-arrow-left" @click="goback">返回</el-link>
        </div>
      </div>
      <div class="sub-title">
        <div class="publish-time">发表时间：{{articleList.add_time | dateFilter}}</div>
        <div v-if="articleType==2" class="author">作者：{{articleList.author}}</div>
        <div v-if="articleType==2" class="source">来源：{{articleList.source}}</div>
        <div class="types">文章分类：{{articleList.type == 1 ? '公司新闻' : '行业新闻'}}</div>
        <div v-if="articleType==2" class="link">网址：{{articleList.url}}</div>
      </div>
    </div>
    <div class="detail-contents">
      <div class="detail-contents-top_pic">
        <img :src="realmURL + articleList.top_pic">
      </div>
      <br>
      <div class="detail-contents-body" v-html="articleList.content"></div>
      <el-col v-if="articleList=='-1'">
        <el-result icon="error" title="内容加载失败！" subTitle="请联系管理员或刷新页面重试">
          <template slot="extra">
            <el-button type="primary" size="medium" @click="resetPage">刷新</el-button>
          </template>
        </el-result>
      </el-col>
    </div>
    <el-backtop :visibility-height="300" :bottom="100" />
  </div>
</template>

<script>
import Nav from '@/components/nav'
export default {
  data () {
    return {
      articleList: [],
      articleType: 0,
      id: 0,
    }
  },
  components: {
    Nav,
  },
  methods: {
    getArticle () {
      if (this.articleType == 2) {
        this.$axios.post('http://freeze.ganmiaoedu.com/website/site/getnewsinfo', {
          id: this.id,
        })
          .then(res => {
            if (res.data.code == 1) {
              this.articleList = res.data.data
            } else {
              this.articleList = '-1'
            }
          })
      } else if (this.articleType == 1) {
        this.$axios.post('http://freeze.ganmiaoedu.com/website/site/getdynamicInfo', {
          id: this.id,
        })
          .then(res => {
            if (res.data.code == 1) {
              this.articleList = res.data.data
            } else {
              this.articleList = '-1'
            }
          })
      }
    },
    resetPage () {
      location.reload();
    },
    goback () {
      this.$router.go(-1)
    },
  },
  created () {
    window.scrollTo(0, 0);
    this.id = this.$route.query.id
    this.articleType = this.$route.query.atype
    this.getArticle(this.id)
  },
  // mounted () {
  // },
}
</script>

<style lang="less" scoped>
.detail {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1600px;
  min-height: 1000px;
  .detail-navs {
    width: 100%;
    height: 100px;
    background-color: #00a0e9;
    .navs {
      background: #00a0e9;
    }
  }
  .detail-title {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1400px;
    margin: 0 auto;
    .main-title {
      position: relative;
      display: flex;
      justify-content: center;
      width: 1400px;
      font-size: 40px;
      font-weight: bold;
      color: #000;
      margin: 130px auto 50px;
      .detail-goback {
        position: absolute;
        top: -80px;
        left: 0;
        /deep/ .el-link.el-link--info {
          font-size: 22px;
        }
      }
    }
    .sub-title {
      display: flex;
      width: 1400px;
      color: #6c6d6e;
      font-size: 20px;
      padding-bottom: 15px;
      margin-bottom: 50px;
      border-bottom: 2px solid #e6e6e6;
      .publish-time,
      .author,
      .source,
      .types,
      .link {
        margin-right: 50px;
      }
    }
  }
  .detail-contents {
    width: 1400px;
    margin: 0 auto 150px;
    .detail-contents-top_pic {
      text-align: center;
      & img {
        max-width: 1400px;
        max-height: 900px;
      }
    }
    .detail-contents-body {
      /deep/ p {
        display: flex;
        flex-direction: column;
        color: #3b3d3d !important;
        font-size: 22px !important;
        line-height: 36px !important;
        text-indent: 45px !important;
        letter-spacing: 3px !important;
        img {
          margin: 30px auto !important;
        }
      }
    }
  }
}
</style>